/* MapComponent.css */

.container {
  position: fixed;
  height: 100%;
  width: 100%;
}
  
  .sidebar {
    position: absolute;
    top: 0;
    right: -400px;
    height: calc(100% - 40px);
    width: calc(400px - 40px);
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow-y: auto;
    padding: 20px;
    transition: right 0.4s ease-in-out; /* Transition effect */

  }
  .sidebar.open {
    right: 0; /* Move on-screen */
  }
  .close-button, .save-button {
    display: block;
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
  }

  .save-button{
    background-color: #1e52cb;
  }
  
  .close-button:hover {
    background-color: #ff1a1a;
  }
  
.input-group {
  margin-bottom: 10px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}